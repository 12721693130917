
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import FMCG1 from '../../images/industries/FMCG1.jpg'

function FMCG_Industries() {

 
  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Fast-Moving Consumer Goods"
        img={FMCG1}
        desc="We provide innovative technologies that support the rapid pace of the Fast-Moving Consumer Goods industry, driving operational excellence and market agility." />

      <SubIntro1 title={'Enhanced Bussiness Process Services'}
        desc1={'Successful operation in the highly competitive Fast-Moving Consumer Goods (FMCG) market requires fast adaptability, consumer interaction, and operational performance.'}
        desc2={'These challenges are taken into consideration while designing our IT solutions, which feature robust, scalable, and cutting-edge technology that enhance company operations across the FMCG value chain.Our tailored IT solutions enhance distribution networks, provide actionable insights, and speed inventory management, all in recognition of the unique needs of the fast-moving consumer goods (FMCG) industry. We empower FMCG companies to make data-driven decisions that enhance their competitiveness and marketability by leveraging state-of-the-art technologies such as artificial intelligence (AI), the Internet of Things (IoT), and predictive analytics.'}
        desc3={'Our IT solutions are designed with a wide range of state-of-the-art capabilities to improve operational excellence and market agility for the FMCG industry. Our innovative analytics solutions support organizations in taking preemptive measures by offering valuable insights into customer behavior and industry trends. '} />

      <Intro video_link={"https://player.vimeo.com/video/1034768564?h=406e135ee8&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} videoType={"intro-FMCG-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default FMCG_Industries

