import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Intro1 from '../components/Intro1';
import Intro2 from '../components/Intro2';
import Text_section from '../components/Text_section';


const Home = () => {
    return (
        <div className=''>
            <Hero />
            <Intro1 />
            <Intro video_link={"https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write"} videoType={'intro-video'}/>
            <Text_section/>
            <Services />
            <Intro2/>
            <Cta />
            <Portfolio />
            <Footer />
        </div>

    )
}

export default Home;

