import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import EPC1 from '../../images/Products/EPC1.jpg'
import EPC2 from '../../images/Products/EPC2.jpg'
import EPC3 from '../../images/Products/EPC3.jpg'
import EPC4 from '../../images/Products/EPC4.jpg'

function EPC_Product() {




  return (
    <div className='bg-white '>
    <HeroSubPages
      title="E-Wallet and Prepaid Card"
      img={EPC1}
      desc="Our E-Wallet and Prepaid Card solutions provide a seamless and secure way to manage digital transactions and control spending. The E-Wallet allows users to store multiple payment methods in one convenient app, offering easy access to funds and quick payment options." />



    <SubIntro2 title={'Process your transactions with our modern and secure platform'}
      desc1={'International transfers, digital payments, spend management, on-demand delivery, digital banking, and more—our E-Card offers an exceptional experience with secure and swift transactions.'}
      desc2={"It also helps elevate your brand through free branding and advertising opportunities, ensuring you achieve both efficient financial management and increased brand visibility."}
      desc3={'Our E-Card simplifies international transfers, digital payments, and spend management with enhanced security and speed.'}
      videoLink={"https://player.vimeo.com/video/1034557548?h=fdf3a62dd5&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={EPC2}
      title={'Secure Digital Payments'}
      desc1={'E-wallets offer a secure and convenient way to make digital payments. They often include encryption, tokenization, and biometric authentication (like fingerprint or facial recognition) to protect user data and transactions'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={EPC3}
        title={'Instant Money Transfer'}
        desc1={'E-wallets enable users to transfer money instantly to other users, bank accounts, or merchants. This can be done domestically and, in some cases, internationally. '}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={EPC3}
        title={"Integration with Various Services"}
        desc1={"E-wallets can integrate with a wide range of services, such as online shopping, bill payments, mobile recharges, and booking tickets (flights, buses, movies, etc.). "} />

    </div>

    <MirrorFeaturesProductCard
      img={EPC4}
      title={'Integration with Various Services'}
      desc1={'E-wallets can integrate with a wide range of services, such as online shopping, bill payments, mobile recharges, and booking tickets (flights, buses, movies, etc.).'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    {/* <TeamCards /> */}
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default EPC_Product
