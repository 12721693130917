import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'

import StatBox from '../../components/StatBox'


import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import LMS1 from '../../images/Products/LMS1.jpg'
import LMS2 from '../../images/Products/LMS2.jpg'
import LMS3 from '../../images/Products/LMS3.jpg'
import LMS4 from '../../images/Products/LMS4.jpg'


function LMS_Product() {


  return (
    <div className='bg-white '>
    <HeroSubPages
      title="Loan Management System"
      img={LMS1}
      desc="Our Loan Management System (LMS) simplifies and automates the entire loan lifecycle, from application to repayment. Designed to streamline processes, reduce administrative overhead, and enhance accuracy, our LMS ensures efficient loan processing and management." />



    <SubIntro2 title={'Unlock the benefits of efficiency with Our Loan management system'}
      desc1={'Managing loans has never been easier. Our Loan Management System (LMS) was designed to automate and simplify every aspect of the lending process from application through repayment.'}
      desc2={'Whether you are a bank, credit union, or a lending company, the LMS provides all of the necessary tools to effectively and securely manage your loans.'}
      desc3={'Our LMS offers advanced features such as real-time loan tracking, automated payment scheduling, and comprehensive reporting to streamline your operations. '}
      videoLink={"https://player.vimeo.com/video/1034558035?h=8679a4bb3d&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={LMS2}
      title={'Loan Origination'}
      desc1={'Easily manage loan applications with automated workflows. Quickly assess borrower creditworthiness and approve loans more quickly with streamlined loan processing.'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={LMS3}
        title={'Document Management'}
        desc1={'Store and manage all loan documents securely and at ease. Easily access files that are important, eliminate paper-based files, and improve compliance.'}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={LMS3}
        title={"Document Management"}
        desc1={"Store and manage all loan documents securely and at ease. Easily access files that are important, eliminate paper-based files, and improve compliance."} />

    </div>

    <MirrorFeaturesProductCard
      img={LMS4}
      title={'Customer Portal'}
      desc1={'An intuitive self-service customer portal provides customers with an uninterrupted experience. Allow borrowers to apply for a loan, view loan status, and repayment through the portal.'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    {/* <TeamCards /> */}
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default LMS_Product
