import React, { useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import Notiflix from 'notiflix';
import axios from 'axios';

const Contact = () => {

    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone_number: '',
        message: '',
        captchaInput: '',
    });

    const [loading, setLoading] = useState(false);
    const [captcha, setCaptcha] = useState(generateCaptcha());

    function generateCaptcha() {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 6; i++) {
            result += chars.charAt(Math.floor(Math.random() * chars.length));
        }
        return result;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Basic validation
        if (!formData.first_name || !formData.last_name || !formData.email || !formData.message) {
            Notiflix.Notify.failure('Please fill in all required fields.');
            return;
        }

        // CAPTCHA validation
        if (formData.captchaInput !== captcha) {
            Notiflix.Notify.failure('CAPTCHA is incorrect. Please try again.');
            setCaptcha(generateCaptcha()); // Reset CAPTCHA
            return;
        }

        setLoading(true);

        try {
            const response = await axios.post('/api/backend/send-email.php', formData);
            // console.log(response,"this is the gole")
            if(response.data.response){

                Notiflix.Notify.success('Message sent successfully!');
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone_number: '',
                    message: '',
                    captchaInput: '',
                });
                setCaptcha(generateCaptcha()); // Reset CAPTCHA after success

            }else{

                Notiflix.Notify.failure('Something Went Wrong. Please try again later.');    

            }
          
        } catch (error) {
            console.error(error);
            Notiflix.Notify.failure('Failed to send message. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <div>
                <NavBar />
            </div>
            <div id="contact" className="flex justify-center items-center mt-8 w-full bg-white py-12 lg:py-24">
                <div className="container mx-auto my-8 px-4 lg:px-20" data-aos="zoom-in">
                    <form onSubmit={handleSubmit}>
                        <div className="w-full bg-white p-8 my-4 md:px-12 lg:w-9/12 lg:pl-20 lg:pr-40 mr-auto rounded-2xl shadow-2xl">
                            <div className="flex">
                                <h1 className="font-bold text-center lg:text-left text-blue-900 uppercase text-4xl">Send us a message</h1>
                            </div>
                            <div className="grid grid-cols-1 gap-5 md:grid-cols-2 mt-5">
                                <div>
                                    <input
                                        name="first_name"
                                        value={formData.first_name}
                                        onChange={handleInputChange}
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="First Name*"
                                    />
                                </div>
                                <div>
                                    <input
                                        name="last_name"
                                        value={formData.last_name}
                                        onChange={handleInputChange}
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Last Name*"
                                    />
                                </div>
                                <div>
                                    <input
                                        name="email"
                                        value={formData.email}
                                        onChange={handleInputChange}
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="email"
                                        placeholder="Email*"
                                    />
                                </div>
                                <div>
                                    <input
                                        name="phone_number"
                                        value={formData.phone_number}
                                        onChange={handleInputChange}
                                        className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                        type="number"
                                        placeholder="Phone*"
                                    />
                                </div>
                            </div>
                            <div className="my-4">
                                <textarea
                                    name="message"
                                    value={formData.message}
                                    onChange={handleInputChange}
                                    placeholder="Message*"
                                    className="w-full h-32 bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                ></textarea>
                            </div>
                            {/* CAPTCHA Section */}
                            <div className="mt-4">
                                <p className="text-gray-700 font-semibold">CAPTCHA: <div
                                    style={{
                                        display: "inline-block",
                                        padding: "10px",
                                        backgroundColor: "#f0f0f0",
                                        fontSize: "20px",
                                        marginBottom: "10px",
                                        fontFamily: "monospace",
                                    }}
                                >
                                    {captcha}
                                </div></p>
                                <input
                                    name="captchaInput"
                                    value={formData.captchaInput}
                                    onChange={handleInputChange}
                                    className="w-full bg-gray-100 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Enter CAPTCHA*"
                                />
                            </div>
                            <div className="my-2 w-1/2 lg:w-2/4">
                                <button
                                    type="submit"
                                    disabled={loading}
                                    id="submitBtn"
                                    className={`uppercase text-sm font-bold tracking-wide ${loading ? 'bg-gray-400' : 'bg-gray-500 hover:bg-blue-900'
                                        } text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline`}
                                >
                                    {loading ? 'Sending...' : 'Send Message'}
                                </button>
                            </div>
                        </div>
                    </form>
                    {/* Additional Information Section */}

                    <div
                        className="w-full lg:-mt-96 lg:w-2/6 px-8 py-6 ml-auto bg-gradient-to-r from-blue-900 to-blue-600 rounded-2xl">
                        <div className="flex flex-col text-white">

                            <div className="flex my-4 w-2/3 lg:w-3/4">
                                <div className="flex flex-col">
                                    <i className="fas fa-map-marker-alt pt-2 pr-2" />
                                </div>
                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Office Address</h2>
                                    <p className="text-gray-400">172/376, 1st Floor, Roy Building Siliguri – 734006, India</p>
                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <div className="flex flex-col">
                                    <i className="fas fa-phone-alt pt-2 pr-2" />
                                </div>

                                <div className="flex flex-col">
                                    <h2 className="text-2xl">Call Us</h2>
                                    <p className="text-gray-400">Tel:+91-6289997989</p>

                                    <div className='mt-5'>
                                        <h2 className="text-2xl">Send an E-mail</h2>
                                        <p className="text-gray-400">contact@topfinglobal.com</p>
                                    </div>

                                </div>
                            </div>

                            <div className="flex my-4 w-2/3 lg:w-1/2">
                                <a href="#" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><path d="M13.397 20.997v-8.196h2.765l.411-3.209h-3.176V7.548c0-.926.258-1.56 1.587-1.56h1.684V3.127A22.336 22.336 0 0 0 14.201 3c-2.444 0-4.122 1.492-4.122 4.231v2.355H7.332v3.209h2.753v8.202h3.312z"></path></svg>
                                </a>
                                <a href="https://www.linkedin.com/company/topfinglobal/?originalSubdomain=in" target="_blank" rel="noreferrer" className="rounded-full flex justify-center bg-white h-8 text-blue-900  w-8  mx-1 text-center pt-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className='fill-current font-black hover:animate-pulse'><circle cx="4.983" cy="5.009" r="2.188"></circle><path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path></svg>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Contact;
