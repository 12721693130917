
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'


import IT1 from '../../images/industries/IT1.jpg'

function HC_Industries() {




  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Information Technology Industries"
        img={IT1}
        desc="Optimize your organization's operations and performance with our advanced Business Process Solutions and Services. Enhance planning, forecasting, and decision-making processes to ensure that your workflows are streamlined, and resources are utilized effectively and efficiently" />

      <SubIntro1 title={'Transform Your Business with Innovative IT Solutions and Expertise'}
        desc1={"Information technology is the cornerstone of every successful business in today's digitally first world. At TopFin Global, we're dedicated to offering state-of-the-art IT solutions which will improve productivity, stimulate enlargement, and raise client satisfaction."}
        desc2={'Our group of skilled IT specialists provides a wide variety of services, from continuous support and maintenance to strategic IT planning. Whether you’re looking to modernize your infrastructure, leverage cloud capabilities, or gain actionable insights through advanced analytics, our expert team is dedicated to providing tailored solutions that empower your business to excel in a digital-first world. Enhance operational efficiency with our bespoke software solutions.'}
        desc3={"Our information technology solutions will change the way your business runs since they were created with creativity, security, and efficiency in mind. We provide advanced cloud solutions, robust cybersecurity, specialist software development, and tailored IT infrastructure. Our proactive managed services and analytics help your business function smoothly and make data-driven decisions in a continuously changing digital world, ensuring that your technology not only supports but also accelerates your company's growth."} />

      <Intro video_link={"https://player.vimeo.com/video/1034768564?h=406e135ee8&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} videoType={"intro-IT-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default HC_Industries

