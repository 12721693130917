import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import DMS1 from '../../images/Products/DMS1.jpg'
import DMS2 from '../../images/Products/DMS2.jpg'
import DMS3 from '../../images/Products/DMS3.jpg'
import DMS4 from '../../images/Products/DMS4.jpg'

function DMS_Product() {




  return (
    <div className='bg-white '>
    <HeroSubPages
      title="Document Management System"
      img={DMS1}
      desc="Our Document Management System (DMS) provides a robust solution for organizing, storing, and retrieving documents efficiently. Designed to streamline document workflows, it offers features such as secure file storage, advanced search capabilities, and automated document indexing." />



    <SubIntro2 title={'Organize, secure, and access your documents seamlessly!'}
      desc1={"In today's fast-moving business circle, efficient document management is the basis on which productivity and compliance can be sustained."}
      desc2={"Our DMS offers a state-of-the-art solution to cater to all document handling requirements, starting from creation to storage and retrieval."}
      desc3={"Our Document Management System (DMS) enhances operational efficiency by providing secure, centralized access to documents, automating workflows, and ensuring compliance with industry standards."}
      videoLink={'https://player.vimeo.com/video/1007461171?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write'}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={DMS2}
      title={'Centralized Document Storage'}
      desc1={'All your documents will be stored in a secure, centralized manner. Documents will be effectively storable, organized, and accessible from anywhere at any time.'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={DMS3}
        title={'Advanced Search Functionality'}
        desc1={'Have the materials you need in the blink of an eye with our advanced search features. Search by keyword, document type, date, or tag-get the documents in seconds.'}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={DMS3}
        title={"Advanced Search Functionality"}
        desc1={"Have the materials you need in the blink of an eye with our advanced search features. Search by keyword, document type, date, or tag-get the documents in seconds."} />

    </div>

    <MirrorFeaturesProductCard
      img={DMS4}
      title={'Secure Access and Permission'}
      desc1={'Keep sensitive information safe by controlling access. Role-based permissions will allow you to give permissions based on roles and responsibilities. Permit access or modification of documents only to those who are entitled with such responsibilities.'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    {/* <TeamCards /> */}
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default DMS_Product
