
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import HC1 from '../../images/industries/HC1.jpg'

function HC_Industries() {


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Healthcare Industry"
        img={HC1}
        desc="The health care industry is rapidly evolving with advancements in technology, data management, and patient care. Our solutions are designed to enhance the efficiency of health care delivery, improve patient outcomes, and streamline operations. By integrating cutting-edge technologies and offering strategic support." />

      <SubIntro1 title={'Shaping Healthcare’s Future with Custom IT Solutions'}
        desc1={"In today's digital age, healthcare organizations are under increasing pressure to deliver high-quality care while managing rising costs and improving patient satisfaction. From secure data management to telehealth integration, we provide comprehensive IT services that help healthcare providers deliver better patient care, improve operational efficiency, and stay compliant with regulatory standards."}
        desc2={'We offer a full range of IT services. These services include secure data management & telehealth integration. Our solutions help healthcare providers enhance patient care, boost operational efficiency, and keep with all the necessary regulations'}
        desc3={"Our Healthcare IT Solutions fit the unique needs of the healthcare industry. They’re designed with care. We deliver strong & secure that boost all parts of your operations. With our advanced telehealth platforms, reaching patients is easier. You can provide care from afar without. Plus, our smart healthcare analytics tools give insights you can use right away. These insights help you make better decisions and improve patient care."} />

      <Intro video_link={"https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write"} videoType={"intro-hc-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default HC_Industries

