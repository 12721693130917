
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import BF1 from '../../images/industries/BF1.jpg'

function BF_Industries() {

 

  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Banking and Financial Industry"
        img={BF1}
        desc="In the dynamic world of banking and finance, our IT solutions drive innovation and efficiency. We offer tailored technologies that enhance security, streamline operations, and optimize customer experiences. Our expertise helps financial institutions navigate regulatory challenges, improve data management, and stay ahead of industry trends" />

      <SubIntro1 title={'Unlock Efficiency, Security, and Growth in the Financial Sector'}
        desc1={'In the fast-changing world of banking & finance, using the latest IT solutions is key to staying ahead. At TopFin Global, provide specific IT services that meet the distinct needs of the financial industry.'}
        desc2={"Our custom solutions focus on boosting security, improving efficiency, and aiding growth strategies. This way, your organization can tackle today's challenges and those of the future effectively."}
        desc3={'At TopFin Global, we offer a range of smart IT just for the banking & financial sector. Our Top-notch services feature strong security measures which keep your data safe & help you to  meet various regulations.'} />

      <Intro video_link={"https://player.vimeo.com/video/1034768564?h=406e135ee8&amp;title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} videoType={"intro-BF-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default BF_Industries

